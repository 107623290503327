import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { accountAPI } from "../services";

const initialState = {
  info: {},
};

export const AccountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    // builder.addCase(
    //   fetchAccountsByUserIdAndMovements.fulfilled,
    //   (state, action) => {
    //     state.info = action.payload;
    //   }
    // );
  },
});

// Action creators are generated for each case reducer function

export default AccountSlice.reducer;
