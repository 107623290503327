import React, { useEffect, useState } from "react";
import "./Pack.scss";
import numbro from "numbro";
import { Link, useLocation } from "react-router-dom";
import { movementType } from "../../../constants";
const defaultItems = [
  {
    value: movementType.INCOME,
    amount: 0,
    color: "#28D628",
    label: "Ingresos",
  },
  {
    value: movementType.PURCHASE,
    amount: 0,
    color: "#D62828",
    label: "Gastos",
  },
  { value: "TOTAL", amount: 0, color: "#FCBF49", label: "Total" },
];
export default function Pack({ type, data, id }) {
  const [itemsToShow, setItemsToShow] = useState(defaultItems);
  useEffect(() => {
    const localData = defaultItems.map((item) => {
      return { ...item, amount: data[item.value] };
    });

    setItemsToShow(localData);
  }, [data, type]);

  const location = useLocation();

  return (
    <div className="Pack">
      <h2>{type}</h2>
      {itemsToShow?.length ? (
        itemsToShow.map((item) => (
          <Link
            className="Pack_item"
            key={item.value}
            style={{ "--color": item.color }}
            to={{
              pathname: `detail/${item.value}`,
              search: location.search + `${id ? "&id=" + id : ""}`,
            }}
          >
            {item.label} : {numbro(item.amount).formatCurrency().toUpperCase()}
          </Link>
        ))
      ) : (
        <></>
      )}
    </div>
  );
}
