import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import _ from "underscore";
import { movementType } from "../../constants";
// import { fetchAccountsByUserIdAndMovements } from "../../store/accounts";

export default function useOverviewInfo() {
  const [searchParams] = useSearchParams();
  const { accounts, users, isLoading } = useSelector(
    (state) => state.overview,
    _.isEqual
  );
  const source = searchParams.get("source");

  const [overview, setOverview] = useState([]);

  const getGeneralInfo = useCallback(() => {
    const generalOverview = accounts.reduce(
      (localOverview, account) => {
        const accountOverview = getAccountOverview(account);
        return {
          [movementType.PURCHASE]:
            localOverview[movementType.PURCHASE] +
            accountOverview[movementType.PURCHASE],
          [movementType.INCOME]:
            localOverview[movementType.INCOME] +
            accountOverview[movementType.INCOME],
          TOTAL: localOverview["TOTAL"] + accountOverview["TOTAL"],
        };
      },
      {
        [movementType.PURCHASE]: 0,
        [movementType.INCOME]: 0,
        TOTAL: 0,
      }
    );
    return [{ type: "general", data: generalOverview, id: "" }];
  }, [accounts]);

  const allUsersOverview = useCallback(() => {
    return users.map((user) => {
      const userAccounts = accounts.filter(
        (account) => account.userId === user.id
      );
      const accountsOverview = userAccounts.reduce(
        (localOverview, account) => {
          const accountOverview = getAccountOverview(account);
          return {
            [movementType.PURCHASE]:
              localOverview[movementType.PURCHASE] +
              accountOverview[movementType.PURCHASE],
            [movementType.INCOME]:
              localOverview[movementType.INCOME] +
              accountOverview[movementType.INCOME],
            TOTAL: localOverview["TOTAL"] + accountOverview["TOTAL"],
          };
        },
        {
          [movementType.PURCHASE]: 0,
          [movementType.INCOME]: 0,
          TOTAL: 0,
        }
      );

      return { type: user.name, data: accountsOverview, id: user.id };
    });
  }, [accounts, users]);

  const getAllAccountsOverview = useCallback(() => {
    return accounts.map((account) => {
      return {
        type: account.name,
        data: getAccountOverview(account),
        id: account.id,
      };
    });
  }, [accounts]);

  const getAccountOverview = (account) => {
    const allMovements = account.movements.reduce(
      (overview, movement) => {
        return {
          ...overview,
          [movement.type]: overview[movement.type] + movement.amount,
        };
      },
      {
        [movementType.PURCHASE]: 0,
        [movementType.INCOME]: 0,
      }
    );

    return {
      ...allMovements,
      TOTAL:
        allMovements[movementType.INCOME] - allMovements[movementType.PURCHASE],
    };
  };

  const sourceFunctionMap = useMemo(() => {
    return {
      general: getGeneralInfo,
      personal: allUsersOverview,
      account: getAllAccountsOverview,
    };
  }, [getGeneralInfo, allUsersOverview, getAllAccountsOverview]);

  useEffect(() => {
    setOverview(sourceFunctionMap[source || "general"]());
  }, [source, sourceFunctionMap]);

  return [overview, isLoading];
}
