import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { appPaths } from "../../router";
import useDidUpdateEffect from "../../hooks/useDidUpdateEffect/useDidUpdateEffect";
import { familyAPI } from "../../services";
import { useSelector } from "react-redux";
import "./addUserToFamily.scss";
import logoNegro from "../../assets/logonegro.png";

export default function AdduserToFamily() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { info: adminInfo } = useSelector((state) => state.user);

  useDidUpdateEffect(() => {
    const addUser = async () => {
      await familyAPI.addUserToFamily(adminInfo.familyGroupId, userId);
      navigate(appPaths.HOME);
    };
    if (adminInfo?.familyGroupId) {
      addUser();
    }
  }, [userId, navigate, adminInfo]);
  return (
    <div className="addUser">
      <img className="loading" src={logoNegro} alt="loading" />
    </div>
  );
}
