import React from "react";
import "./Field.scss";
import logoNegro from "../../assets/logonegro.png";

export default function Field({
  value,
  name,
  label,
  onChange,
  type,
  children,
  required = false,
  isLoading = false,
}) {
  return (
    <div className="Field">
      <label className="Field_label" htmlFor={name} required>
        {label}
        {required && <span className="required"></span>}
      </label>
      {isLoading ? (
        <div className="Field_loading">
          {" "}
          <img className="loading" src={logoNegro} alt="loading" />
        </div>
      ) : children ? (
        children
      ) : (
        <input
          required={required}
          className="Field_input"
          type={type}
          name={name}
          id={name}
          value={value}
          onChange={onChange}
        />
      )}
    </div>
  );
}
