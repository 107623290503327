import React, { useEffect } from "react";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import "./aws-auth.scss"

export default function AuthForm() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";

  const formFields = {
    signUp: {
      email: {
        order: 1,
      },
      name: {
        order: 2,
      },
      username: {
      order: 3,
      required: false,
      hide: true
    },
      birthdate: {
        order: 4,
      },
      phone_number: {
        order: 5,
      },
    },
  };

  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);
  return (
    <div className="loginForm">
      <Authenticator formFields={formFields} signUpAttributes={['email', 'name', 'birthdate', 'phone_number']}></Authenticator>
    </div>
  );
}
