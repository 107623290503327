import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { familyAPI } from "../services";

export const fetchFamilyByIdWithMovements = createAsyncThunk(
  "overview/fetchFamilyByIdWithMovements",
  async ({ id, startDate, endDate }) => {
    const response = await familyAPI.fetchByIdWithMovements(
      id,
      startDate,
      endDate
    );
    return response.data;
  }
);

const initialState = {
  family: {},
  accounts: [],
  users: [],
  isLoading: false,
};

export const overviewSlice = createSlice({
  name: "overview",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchFamilyByIdWithMovements.fulfilled, (state, action) => {
      // Add user to the state array
      const { payload } = action;

      state.family = { id: payload.id, name: payload.name };

      const users = payload.users.map((user) => {
        return { id: user.id, name: user.name, accounts: user.accounts };
      });

      state.users = users;

      const familyAccounts = payload.users.reduce((accounts, user) => {
        return accounts.concat(user.accounts);
      }, []);

      state.accounts = familyAccounts;
      state.isLoading = false;
    });
    builder.addCase(fetchFamilyByIdWithMovements.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchFamilyByIdWithMovements.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

// Action creators are generated for each case reducer function

export default overviewSlice.reducer;
