import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./user";
import familyReducer from "./family";
import accountReducer from "./accounts";
import overviewReducer from "./overview";
import categoriesReducer from "./categories";

export const store = configureStore({
  reducer: {
    user: userReducer,
    overview: overviewReducer,
    categories: categoriesReducer,
  },
  middleware: (getDefaultMiddleware) => {
    // Add middleware here if needed
    return getDefaultMiddleware();
  },
});
