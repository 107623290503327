import React, { useCallback, useEffect, useMemo, useState } from "react";
import useOverviewInfo from "../useOverviewInfo/useOverview";
import { useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

export default function useDetail() {
  const [searchParams] = useSearchParams();
  const params = useParams();

  const { accounts } = useSelector((state) => state.overview);

  const [detail, setDetail] = useState([]);

  const getAllMovements = useCallback((accounts) => {
    return accounts?.reduce((movements, account) => {
      return movements.concat(account.movements);
    }, []);
  }, []);

  const getMovementsByType = useCallback(
    (movements) => {
      const { type } = params;

      if (type === "TOTAL") {
        return movements;
      } else {
        return movements?.filter((movement) => movement.type === type);
      }
    },
    [params]
  );

  const getAccountsBySource = useCallback(
    (accounts) => {
      const source = searchParams.get("source");
      const id = searchParams.get("id");

      const sourceMapFilterField = {
        personal: "userId",
        account: "id",
      };

      if (source === "general") {
        return accounts;
      } else {
        return accounts?.filter(
          (account) => account[sourceMapFilterField[source]] === id
        );
      }
    },
    [searchParams]
  );

  const orderMovementsByDate = (movements) => {
    const sortingMovements = (a, b) => {
      if (moment(a.date).isBefore(b.date)) {
        return 1;
      } else if (moment(a.date).isAfter(b.date)) {
        return -1;
      }
      return 0;
    };
    return movements.sort(sortingMovements);
  };
  useEffect(() => {
    const accountsBySource = getAccountsBySource(accounts);

    const allMovements = getAllMovements(accountsBySource);

    const movementsByType = getMovementsByType(allMovements);

    const orderedMovements = orderMovementsByDate(movementsByType);

    setDetail(orderedMovements);
  }, [
    params,
    searchParams,
    accounts,
    getAllMovements,
    getMovementsByType,
    getAccountsBySource,
  ]);

  return { detail };
}
