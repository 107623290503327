import React, { useEffect, useMemo } from "react";
import "./NavBar.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

export default function NavBar({ type }) {
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.has("dateSpan") || !searchParams.has("source")) {
      const currentDate = new Date();
      setSearchParams({
        dateSpan: "month",
        date: currentDate,
        source: "general",
      });
    }
  }, []);

  const listOfTabs = useMemo(() => {
    const mapOfTabs = {
      source: [
        { value: "general", label: "general" },
        { value: "personal", label: "personal" },
        { value: "account", label: "cuenta" },
      ],
      dateSpan: [
        { value: "month", label: "Mes" },
        {
          value: "week",
          label: "Semana",
        },
        { value: "day", label: "Hoy" },
      ],
    };
    return mapOfTabs[type];
  }, [type]);

  const handleNavigation = (tabName) => {
    searchParams.set(type, tabName);
    setSearchParams(searchParams);
  };

  return (
    <nav className="NavBar">
      {listOfTabs.length ? (
        listOfTabs.map((item) => (
          <div
            className={`NavBar_item ${
              searchParams.get(type) === item.value ? "active" : ""
            }`}
            key={item.value}
            onClick={() => handleNavigation(item.value)}
          >
            {item.label}
          </div>
        ))
      ) : (
        <></>
      )}
    </nav>
  );
}
