import React from "react";
import LogIn from "../views/LogIn";
import { PrivateRoute } from "../hooks/authHooks/auth";
import Home from "../views/Home/home";
import { Fragment } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Detail from "../views/Detail";
import OverView from "../views/OverView/OverView";
import NewMovement from "../views/NewMovement";
import CreateFamily from "../views/CreateFamily";
import AdduserToFamily from "../views/AddUserToFamily";
import NewAccount from "../views/NewAccount";
import NewCategory from "../views/NewCategory/NewCategory";

export const appPaths = {
  HOME: "/",
  LOGIN: "/login",
  DETAIL: "/detail/:type",
  NEWMOVEMENT: "/new",
  NEW_FAMILY: "/new-family",
  ADD_USER: "/new-family-user/:userId",
  NEW_ACCOUNT: "/new-account",
  NEW_CATEGORY: "/new-category",
  EDIT_MOVEMENT: "/new/:id",
};

export default function Router() {
  return (
    <BrowserRouter>
      <Fragment>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route exact path={appPaths.HOME} element={<Home />}>
              <Route path={appPaths.HOME} element={<OverView />}></Route>
              <Route exact path={appPaths.DETAIL} element={<Detail />}></Route>
              <Route
                exact
                path={appPaths.NEWMOVEMENT}
                element={<NewMovement />}
              ></Route>
              <Route
                exact
                path={appPaths.EDIT_MOVEMENT}
                element={<NewMovement />}
              ></Route>
              <Route
                exact
                path={appPaths.NEW_FAMILY}
                element={<CreateFamily />}
              ></Route>
              <Route
                exact
                path={appPaths.ADD_USER}
                element={<AdduserToFamily />}
              ></Route>
              <Route
                exact
                path={appPaths.NEW_ACCOUNT}
                element={<NewAccount />}
              ></Route>
              <Route
                exact
                path={appPaths.NEW_CATEGORY}
                element={<NewCategory />}
              ></Route>
            </Route>
          </Route>

          <Route path={appPaths.LOGIN} element={<LogIn />}></Route>
        </Routes>
      </Fragment>
    </BrowserRouter>
  );
}
