import React from "react";
import AuthForm from "./aws-auth";
import "./LogIn.scss";
import logoNegro from "../../assets/logonegro.png";

export default function LogIn() {
  return (
    <div className="LogIn">
      <div className="LogIn_top"></div>
      <div className="LogIn_content">
        <div className="LogIn_content_logo">
          <img src={logoNegro} alt="" />
        </div>
        <AuthForm />
      </div>
      <div className="LogIn_bottom"></div>
    </div>
  );
}
