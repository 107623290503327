import React from "react";

import "./App.scss";

import { Authenticator } from "@aws-amplify/ui-react";
import Router from "./router";
import { PrimeReactProvider } from "primereact/api";

function App() {
  return (
    <PrimeReactProvider>
      <Authenticator.Provider>
        <Router />
      </Authenticator.Provider>
    </PrimeReactProvider>
  );
}

export default App;
