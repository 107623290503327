import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { appPaths } from "../../router";
import useDidUpdateEffect from "../../hooks/useDidUpdateEffect/useDidUpdateEffect";
import useDetail from "../../hooks/useDetail/useDetail";
import DetailItem from "./components/DetailItem";
import "./Detail.scss";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import numbro from "numbro";
import moment from "moment";
import useCategories from "../../hooks/useCategories/useCategories";
import { useSelector } from "react-redux";
import { Tag } from "primereact/tag";
import {
  INCOME,
  PURCHASE,
  typeToSpanishMap,
} from "../../constants/movementType";
import { movementAPI } from "../../services";
import logoNegro from "../../assets/logonegro.png";
import { Button } from "primereact/button";
import "moment/locale/es"; // without this line it didn't work

export default function Detail() {
  const { detail } = useDetail();
  const [expandedRows, setExpandedRows] = useState(null);
  const categories = useSelector((state) => state.categories.info);
  const { users, isLoading } = useSelector((state) => state.overview);

  const [detailedItems, setDetailedItems] = useState([]);

  const navigate = useNavigate();

  const formmatedAmount = (data) => {
    return numbro(data.amount).formatCurrency({
      thousandSeparated: true,
      mantissa: 2,
    });
  };

  const formmatedData = (data) => {
    return moment(data.date)
      .format("dddd, MMMM Do YYYY HH:mm")
      .toLocaleUpperCase();
  };

  const formatedCategory = (date) => {
    if (categories.length) {
      const selectedCategory = categories.find(
        (category) => category.id === date.categoryId
      );
      return selectedCategory.name;
    }
    return "";
  };

  const getOrderSeverity = (type) => {
    switch (type) {
      case INCOME:
        return "success";

      case PURCHASE:
        return "danger";

      default:
        return null;
    }
  };

  const formmatedType = (date) => {
    return (
      <Tag
        value={typeToSpanishMap[date.type]}
        severity={getOrderSeverity(date.type)}
      ></Tag>
    );
  };

  const onRowExpand = async (event) => {
    const extraInfo = await movementAPI.getMovementById(event.data.id);
    setDetailedItems(detailedItems.concat(extraInfo.data));
  };

  const formmatedAccount = useCallback(
    (accountId) => {
      let accountString = "";

      users.forEach((user) => {
        const selectedAccount = user.accounts.find(
          (account) => account.id === accountId
        );
        if (selectedAccount) {
          accountString = user.name + " - " + selectedAccount.name;
          return;
        }
      });

      return accountString;
    },
    [users]
  );
  const expansionTemplate = useCallback(
    (data) => {
      const extraInfo = detailedItems.find((item) => item.id === data.id);
      return (
       <>
       <h3>Detalles del movimiento</h3> 
       <div className="extra">
       {!extraInfo?.id && (
         <div className="DetailItem_base_info_loading">
           <img className="loading" src={logoNegro} alt="loading" />
         </div>
       )}
       <div className="extra_item">
         {extraInfo?.accountId && (
           <span>
             <u>Cuenta:</u> {formmatedAccount(extraInfo.accountId)}
           </span>
         )}
         {extraInfo?.subCategory && (
           <span>
             <u>Sub categoria:</u> {extraInfo.subCategory}
           </span>
         )}
         {extraInfo?.billLink && (
           <span>
             <u>Factura: </u>
             <a href={extraInfo.billLink} target="blank">
               {extraInfo.billLink}
             </a>
           </span>
         )}
       </div>
       <div className="extra_item">
         {extraInfo?.note && (
           <span>
             <u>Nota:</u> {extraInfo.note}
           </span>
         )}
       </div>
     </div></>
      );
    },
    [detailedItems, formmatedAccount]
  );

  const handleEditMovement = (data) => {
    navigate(appPaths.NEWMOVEMENT + "/" + data.id);
  };

  const EditButton = (id) => {
    return <Button onClick={() => handleEditMovement(id)}>Editar</Button>;
  };
  return (
    <div className="card">
      {!isLoading ? (
        <DataTable
          value={detail}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={expansionTemplate}
          onRowExpand={onRowExpand}
          dataKey="id"
        >
          <Column expander={true} style={{ width: "5rem" }} />
          <Column field="type" header="Tipo" body={formmatedType} />
          <Column field="description" header="Descripcion" />
          <Column field="amount" header="Valor" body={formmatedAmount} />
          <Column
            field="categoryId"
            header="Categoria"
            body={formatedCategory}
          />
          <Column field="date" header="Fecha" body={formmatedData} />
          <Column body={EditButton} />
        </DataTable>
      ) : (
        <div className="Detail">
          <img className="loading" src={logoNegro} alt="loading" />
        </div>
      )}
    </div>
  );
}
