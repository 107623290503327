import React, { useCallback, useEffect, useState } from "react";
import "./Header.scss";
import NavBar from "./components/NavBar";
import { useDispatch, useSelector } from "react-redux";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { fetchUserById } from "../../../../store/user";
import useDates from "../../../../hooks/useDate/useDate";
import { fetchFamilyByIdWithMovements } from "../../../../store/overview";
import _ from "underscore";
import { useLocation, useNavigate } from "react-router-dom";
import useDidUpdateEffect from "../../../../hooks/useDidUpdateEffect/useDidUpdateEffect";
import SideMenu from "./components/SideMenu";
import Popup from "../../../../components/Popup/Popup";
import { appPaths } from "../../../../router";
import { fetchCategories } from "../../../../store/categories";

export default function Header() {
  const dispatch = useDispatch();
  const navitate = useNavigate();

  const { user } = useAuthenticator((context) => [context.user]);
  const userInfo = useSelector((state) => state.user);
  const { family } = useSelector((state) => state.overview, _.isEqual);
  const categoriesInfo = useSelector((state) => state.categories.info);

  const location = useLocation();

  const { startDate, endDate } = useDates();

  const [displayFamilyPopUp, setDisplayFamilyPopUp] = useState(false);

  useDidUpdateEffect(() => {
    if (!userInfo?.info?.id) {
      dispatch(fetchUserById(user.username));
    }
  }, [dispatch, user]);

  useDidUpdateEffect(() => {
    if (userInfo?.info.familyGroupId && startDate && endDate) {
      dispatch(
        fetchFamilyByIdWithMovements({
          id: userInfo?.info.familyGroupId,
          startDate: startDate,
          endDate: endDate,
        })
      );
      if (!categoriesInfo) {
        dispatch(fetchCategories(userInfo?.info.familyGroupId));
      }
    } else if (
      userInfo?.info.id &&
      !userInfo?.info.familyGroupId &&
      startDate &&
      endDate
    ) {
      setDisplayFamilyPopUp(true);
    }
  }, [userInfo, dispatch, startDate, endDate]);

  const componentsToDisplay = useCallback(() => {
    const path = location?.pathname;

    if (path.includes("new")) {
      return [];
    }

    if (path.includes("detail")) {
      return [<NavBar type="dateSpan" key="dateSpan"></NavBar>];
    }

    return [
      <NavBar type="source" key="source"></NavBar>,
      <NavBar type="dateSpan" key="dateSpan"></NavBar>,
    ];
  }, [location?.pathname]);

  const handleClosePopUP = () => {
    // setDisplayFamilyPopUp(false);
    alert("No puedes continuar sin crear una familia");
  };
  return (
    <>
      <header className="Header">
        <SideMenu></SideMenu>
        <h1 className="Header_title">{family?.name}</h1>
        {componentsToDisplay().map((component) => component)}
      </header>
      <Popup isActive={displayFamilyPopUp} handleClose={handleClosePopUP}>
        <h1>Debe crear una familia para poder empezar</h1>
        <button
          className="createFamily_button"
          onClick={() => {
            setDisplayFamilyPopUp(false);
            navitate(appPaths.NEW_FAMILY);
          }}
        >
          Crear familia
        </button>
      </Popup>
    </>
  );
}
