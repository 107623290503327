import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { categoryAPI } from "../services";

export const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async (id) => {
    const response = await categoryAPI.fetchAll(id);
    return response.data;
  }
);

const initialState = {
  info: [],
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      // Add user to the state array
      state.info = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function

export default categoriesSlice.reducer;
