import axios from "axios";
import { Auth } from "aws-amplify";

const getToken = async () => {
  const session = await Auth.currentSession();

  return session.getIdToken()?.getJwtToken();
};

const API_DEVELOPMENT =
  "https://j7yk08jcse.execute-api.us-east-1.amazonaws.com/development-finanzas/";

const getAxiosConfig = async () => {
  const AuthToken = await getToken();
  return {
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${AuthToken}`,
    },
  };
};

export const userAPI = {
  fetchById: async (id) => {
    const axiosConfig = await getAxiosConfig();
    return axios.get(API_DEVELOPMENT + `users/user/${id}`, axiosConfig);
  },
  addUserRequest: async (familyId, userInfo) => {
    const requestBody = {
      familyId,
      userInfo,
    };
    const axiosConfig = await getAxiosConfig();
    return axios.post(
      API_DEVELOPMENT + `users/email/add-user-request`,
      requestBody,
      axiosConfig
    );
  },
};

export const familyAPI = {
  fetchByIdWithMovements: async (id, startDate, endDate) => {
    const axiosConfig = await getAxiosConfig();
    return axios.get(API_DEVELOPMENT + `families/family/${id}`, {
      ...axiosConfig,
      params: {
        showMovements: true,
        startDate: startDate,
        endDate: endDate,
      },
    });
  },
  createFamily: async (data) => {
    console.log("created a new family", data);
    const axiosConfig = await getAxiosConfig();
    return axios.post(API_DEVELOPMENT + "/families/family", data, {
      ...axiosConfig,
    });
  },
  addUserToFamily: async (familyId, userId) => {
    console.log("add user to family", familyId, userId);
    const axiosConfig = await getAxiosConfig();
    return axios.post(
      API_DEVELOPMENT + "/families/family/" + familyId,
      { newUserId: userId },
      {
        ...axiosConfig,
      }
    );
  },
};

export const accountAPI = {
  fetchByUserIdAndMovements: async (id, startDate, endDate) => {
    const axiosConfig = await getAxiosConfig();
    return axios.get(API_DEVELOPMENT + `accounts`, {
      ...axiosConfig,
      params: {
        userId: id,
        showMovements: true,
        startDate: startDate,
        endDate: endDate,
      },
    });
  },
  createNewAccount: async (accountData) => {
    const axiosConfig = await getAxiosConfig();
    console.log("post account account", accountData);
    return axios.post(
      API_DEVELOPMENT + `accounts/account`,
      accountData,
      axiosConfig
    );
  },
};

export const movementAPI = {
  getMovementById: async (id) => {
    const axiosConfig = await getAxiosConfig();
    return axios.get(API_DEVELOPMENT + "/movements/movement/" + id, {
      ...axiosConfig,
    });
  },
  createMovement: async (data) => {
    const axiosConfig = await getAxiosConfig();
    return axios.post(API_DEVELOPMENT + "/movements/movement", data, {
      ...axiosConfig,
    });
  },
  editMovement: async (data) => {
    const axiosConfig = await getAxiosConfig();
    return axios.patch(API_DEVELOPMENT + "movements/movement/"+ data.id, data, {
      ...axiosConfig,
    });
  },
};

export const categoryAPI = {
  fetchAll: async (familyId) => {
    const axiosConfig = await getAxiosConfig();
    return axios.get(API_DEVELOPMENT + "categories", {
      ...axiosConfig,
      params: {
        familyGroupId: familyId,
      },
    });
  },
  createCategory: async (data) => {
    const axiosConfig = await getAxiosConfig();
    console.log("new category", data);
    return axios.post(API_DEVELOPMENT + "categories/category", data, {
      ...axiosConfig,
    });
  },
};

// MOCK CALLS

// export const userAPI = {
//   fetchById: async (id) => {
//     console.log("userAPI call");

//     return new Promise((resolve) => {
//       resolve({
//         data: {
//           id: "72190380-b2be-4875-9705-891eade64cd9",
//           name: "Felipe Cuervo",
//           familyGroupId: "5c81625d-1155-4921-b805-7bd40a507754",
//           email: "felipecuervo12@gmail.com",
//           createdAt: "2023-11-05T17:07:30.742Z",
//           updatedAt: "2023-11-05T17:08:48.921Z",
//           accounts: [],
//         },
//       });
//     });
//   },
// };

// export const familyAPI = {
//   fetchByIdWithMovements: async (id) => {
//     console.log("familyApi call");
//     return new Promise((resolve) => {
//       resolve({
//         data: {
//           id: "5c81625d-1155-4921-b805-7bd40a507754",
//           name: "Cuervo Cano",
//           familyAdmin: "72190380-b2be-4875-9705-891eade64cd9",
//           createdAt: "2023-11-05T17:08:48.761Z",
//           updatedAt: "2023-11-05T17:08:48.761Z",
//           users: [
//             {
//               id: "72190380-b2be-4875-9705-891eade64cd9",
//               name: "Felipe Cuervo",
//               familyGroupId: "5c81625d-1155-4921-b805-7bd40a507754",
//               email: "felipecuervo12@gmail.com",
//               createdAt: "2023-11-05T17:07:30.742Z",
//               updatedAt: "2023-11-05T17:08:48.921Z",
//               accounts: [],
//             },
//             {
//               id: "f90ddd5e-9a7b-4a4d-8cf2-6dabe4efd628",
//               name: "Epilef Ovreuc",
//               familyGroupId: "5c81625d-1155-4921-b805-7bd40a507754",
//               email: "epilefovreuc@gmail.com",
//               createdAt: "2023-11-05T17:12:53.485Z",
//               updatedAt: "2023-11-05T19:32:20.390Z",
//               accounts: [],
//             },
//           ],
//         },
//       });
//     });
//   },
//   createFamily: async (data) => {
//     console.log("created a new family", data);
//     // const axiosConfig = await getAxiosConfig();
//     // return axios.post(API_DEVELOPMENT + "/families/family", data, {
//     //   ...axiosConfig,
//     // });
//   },
// };

// export const accountAPI = {
//   fetchByUserIdAndMovements: async (id, startDate, endDate) => {
//     console.log("accountApi call");

//     return new Promise((resolve) => {
//       const userAccounts = accountsMockResponse.filter(
//         (account) => account.userId === id
//       );
//       resolve(userAccounts);
//     });
//   },
// };

const accountsMockResponse = [
  {
    id: "29b46f17-5304-4a83-8600-1c1c89dbe4be",
    name: "Ahorros Bancolombia",
    description: "Cuenta de ahorros normal de bancolombia",
    type: "Savings",
    isCredit: false,
    userId: "1418e458-30c1-702f-f30f-d82db9224fa6",
    createdAt: "2023-09-05T22:01:13.109Z",
    updatedAt: "2023-09-05T22:20:12.840Z",
    movements: [
      {
        description: "Salsa BBQ, dulces y agua",
        amount: 12400,
        type: "purchase",
        date: "2023-10-05T00:00:00.000Z",
        id: "a91a4433-2a93-48da-9384-01161a1ca4e3",
      },
    ],
  },
  {
    id: "cbd0e225-d6fe-491f-b296-bb1b7ee143dd",
    name: "Ahorros Pibank",
    description: "Cuenta de ahorros Pibank",
    type: "Savings",
    isCredit: false,
    userId: "1418e458-30c1-702f-f30f-d82db9224fa6",
    createdAt: "2023-09-24T18:45:49.043Z",
    updatedAt: "2023-09-24T18:45:49.043Z",
    movements: [
      {
        description: "Recibo",
        amount: 130000,
        type: "purchase",
        date: "2023-10-24T00:00:00.000Z",
        id: "75e93dc6-6547-11ee-8c99-0242ac120002",
      },
      {
        description: "Recibo",
        amount: 70000,
        type: "purchase",
        date: "2023-10-24T00:00:00.000Z",
        id: "94cb17a0-ffd0-40b8-a072-228f65ec9ac9",
      },
      {
        description: "Agile engine",
        amount: 7000000,
        type: "income",
        date: "2023-10-07T21:31:53.800Z",
        id: "a44d3bd6-6f5c-4c7e-9fdf-461570b29eaf",
      },
    ],
  },
  {
    id: "42356d46-ea2f-4cfa-81a1-edd824bef03b",
    name: "Ahorros Coomeva",
    description: "Cuenta de ahorros Coomeva",
    type: "Savings",
    isCredit: false,
    userId: "94386408-0041-70ae-af0c-d77d89327127",
    createdAt: "2023-09-24T19:17:41.325Z",
    updatedAt: "2023-09-24T19:17:41.325Z",
    movements: [],
  },
];

// export const movementAPI = {
//   getMovementById: (id) => {
//     console.log("movements api call");
//     return new Promise((resolve) => {
//       resolve({
//         data: {
//           id: "a91a4433-2a93-48da-9384-01161a1ca4e3",
//           amount: 12400,
//           description: "Salsa BBQ, dulces y agua",
//           subCategory: "Procesado",
//           type: "purchase",
//           date: "2023-10-05T00:00:00.000Z",
//           billLink: "https://www.google.com/",
//           note: "Una nota super larga para ver como se comporta el componente cuando le pongo una nota super larga. Y la idea esra que mexico iba a asumir todo eso hasta el final de centro america, pero guatemala se independciza",
//           accountId: "29b46f17-5304-4a83-8600-1c1c89dbe4be",
//           categoryId: "cdafa197-e027-478d-b99e-74bd975d06f0",
//           createdAt: "2023-09-05T22:35:04.470Z",
//           updatedAt: "2023-09-05T22:52:54.848Z",
//           category: {
//             id: "cdafa197-e027-478d-b99e-74bd975d06f0",
//             name: "Mercado",
//             type: "purchase",
//             subCategories: [
//               "Procesados",
//               "Proteinas",
//               "Lacteos",
//               "Aseo Personal",
//             ],
//             createdAt: "2023-09-05T22:22:04.061Z",
//             updatedAt: "2023-09-05T22:27:06.661Z",
//           },
//         },
//       });
//     });
//   },
//   createMovement: (data) => {
//     return new Promise((resolve) => {
//       resolve({ data: { ...data } });
//     });
//   },
// };

// export const categoryAPI = {
//   fetchAll: async () => {
//     console.log("category api call");
//     return new Promise((resolve) => {
//       resolve({
//         data: [
//           {
//             id: "cdafa197-e027-478d-b99e-74bd975d06f0",
//             name: "Mercado",
//             type: "purchase",
//             subCategories: [
//               "Procesados",
//               "Proteinas",
//               "Lacteos",
//               "Aseo Personal",
//             ],
//             createdAt: "2023-09-05T22:22:04.061Z",
//             updatedAt: "2023-09-05T22:27:06.661Z",
//           },
//           {
//             id: "21331d65-9ab2-445c-8804-718bb548aad7",
//             name: "Servicios",
//             type: "purchase",
//             subCategories: ["luz", "agua", "gas", "internet", "telefeno Paula"],
//             createdAt: "2023-09-24T18:47:23.162Z",
//             updatedAt: "2023-09-24T18:47:23.162Z",
//           },
//           {
//             id: "e9cbd46b-e25e-42a8-887d-1a84c5ecbc69",
//             name: "Salario",
//             type: "income",
//             subCategories: null,
//             createdAt: "2023-10-07T21:30:27.920Z",
//             updatedAt: "2023-10-07T21:30:27.920Z",
//           },
//         ],
//       });
//     });
//   },
// };

// const { dbconection } = require("/opt/dbConnection");
// const modelBuilder = require("/opt/DBModels");
// const {userModel} = require('/opt/userModel');

// exports.handler = async (event) => {
//   try {
//     const connection = await dbconection();
//     const {FamilyGroup, User, Account} = await modelBuilder(connection);

//     const parameters = event.pathParameters;

//     const returnedFamily = await FamilyGroup.findByPk(parameters.id, {include: {model:User, include: {model:Account ,attributes: ['id', 'name']}}});

//     const response = {
//       statusCode: 200,
//       body: JSON.stringify(returnedFamily),
//         headers: {
//     "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,X-Amz-Security-Token,Authorization,X-Api-Key,X-Requested-With,Accept,Access-Control-Allow-Methods,Access-Control-Allow-Origin,Access-Control-Allow-Headers",
//     "Access-Control-Allow-Origin": "*",
//     "Access-Control-Allow-Methods": "DELETE,GET,HEAD,OPTIONS,PATCH,POST,PUT"
//         },
//     };

//     return response;
//   } catch (error) {
//     console.log("error", error);
//     return {
//       statusCode: 400,
//       body: JSON.stringify({
//         errorType: error["errorType"],
//         errorMessage: error["errorMessage"],
//         name: error["name"],
//       }),
//     };
//   }
// };
