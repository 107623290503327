import React from "react";
import "../Field/Field.scss";
import { InputNumber } from "primereact/inputnumber";

export default function MoneyField({ value, name, label, onChange, required }) {
  return (
    <div className="Field">
      <label className="Field_label" htmlFor={name}>
        {label}
        {required && <span className="required"></span>}
      </label>
      <InputNumber
        required={required}
        size={10}
        inputId="currency-us"
        value={value}
        onValueChange={onChange}
        mode="currency"
        currency="USD"
        locale="en-US"
      />
    </div>
  );
}
