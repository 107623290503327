import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function useDates() {
  const [searchParams] = useSearchParams();

  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });

  const dateSpanMap = useMemo(() => {
    return {
      month: getMonthLimits,
      week: getWeekLimits,
      day: getDayLimits,
    };
  }, []);

  useEffect(() => {
    const date = searchParams.has("date")
      ? new Date(searchParams.get("date"))
      : new Date();
    const dateSpan = searchParams.get("dateSpan");

    setDateRange(dateSpanMap[dateSpan || "month"](date));
  }, [dateSpanMap, searchParams]);

  return { startDate: dateRange.startDate, endDate: dateRange.endDate };
}

const getMonthLimits = (givenDate) => {
  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  const firstDay = getFirstDayOfMonth(
    givenDate.getFullYear(),
    givenDate.getMonth()
  );
  const firstDayNextMonth = getFirstDayOfMonth(
    givenDate.getFullYear(),
    givenDate.getMonth() + 1
  );

  return {
    startDate: firstDay.toDateString(),
    endDate: firstDayNextMonth.toDateString(),
  };
};

const getWeekLimits = (givenDate) => {
  let first_day_of_the_week = new Date(
    givenDate.setDate(givenDate.getDate() - givenDate.getDay())
  );

  let last_day_of_the_week = new Date(
    givenDate.setDate(givenDate.getDate() - givenDate.getDay() + 7)
  );

  return {
    startDate: first_day_of_the_week.toDateString(),
    endDate: last_day_of_the_week.toDateString(),
  };
};

const getDayLimits = (givenDate) => {
  return {
    startDate: new Date(givenDate).toDateString(),
    endDate: new Date(
      givenDate.setDate(givenDate.getDate() + 1)
    ).toDateString(),
  };
};
