import React, { useMemo, useState } from "react";
import "./NewCategory.scss";
import Field from "../../components/Field";
import { Dropdown } from "primereact/dropdown";
import Popup from "../../components/Popup/Popup";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { categoryAPI } from "../../services";
import { Chip } from "primereact/chip";
import { movementType } from "../../constants";
import plusIcon from "../../assets/plus.svg";
import logoNegro from "../../assets/logonegro.png";

const typeList = [
  { label: "Compra", value: movementType.PURCHASE },
  { label: "Ingreso", value: movementType.INCOME },
];

export default function NewCategory() {
  const blankForm = useMemo(() => {
    return {
      name: "",
      type: "",
      subCategories: [],
    };
  }, []);

  let schema = yup.object().shape({
    name: yup.string().required().label("Nombre"),
    type: yup.string().required().label("Tipo"),
    subCategories: yup.array(yup.string()),
  });

  const [data, setData] = useState(blankForm);
  const [error, setError] = useState("");
  const [isOpenConfirmation, setIsOpenconfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [subCategory, setSubCategory] = useState("");

  const { family } = useSelector((state) => state.overview);

  const handleAddSubCategory = () => {
    setData({
      ...data,
      subCategories: data.subCategories.concat([subCategory]),
    });
    setSubCategory("");
  };

  const handleRemoveSubCategory = (categoryToRemove) => {
    const newSubCategories = data.subCategories.filter(
      (category) => category !== categoryToRemove
    );
    setData({ ...data, subCategories: newSubCategories });
  };

  const handleFieldChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const handleValidation = (e) => {
    e.preventDefault();
    schema
      .validate(data)
      .then((valid) => {
        setIsOpenconfirmation(true);
        setError("");
      })
      .catch((error) => {
        setError(error.errors);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await categoryAPI.createCategory({
      ...data,
      familyGroupId: family.id,
      isDefault: false,
    });
    setData(blankForm);
    setIsOpenconfirmation(false);
    setIsLoading(false);
  };

  // function timeout(ms) {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // }
  // async function sleep(fn, ...args) {
  //   await timeout(2000);
  //   return fn(...args);
  // }
  return (
    <>
      {" "}
      <>
        <form className="NewMovement" onSubmit={handleValidation}>
          <h2>Nueva Categoria</h2>
          <Field
            required
            type="text"
            label="Nombre de la categoria"
            name="name"
            value={data.name}
            onChange={(e) => {
              handleFieldChange("name", e.target.value);
            }}
          />
          <Field label="Tipo de movimiento" name="type" required>
            <Dropdown
              value={data.type}
              onChange={(e) => handleFieldChange("type", e.value)}
              options={typeList}
              placeholder="Tipo de gasto"
              className="dropDown"
            />
          </Field>
          <Field label="Sub categoria">
            <div className="bulletListInput">
              <input
                className="bulletListInput_input"
                value={subCategory}
                onChange={(e) => {
                  setSubCategory(e.target.value);
                }}
              />
              {subCategory && (
                <button
                  className="bulletListInput_add"
                  onClick={handleAddSubCategory}
                >
                  <img src={plusIcon} alt="add" />
                </button>
              )}
              <div className="bulletListInput_list">
                {data?.subCategories?.length ? (
                  data.subCategories.map((item) => {
                    return (
                      <Chip
                        key={item}
                        label={item}
                        removable
                        onRemove={() => handleRemoveSubCategory(item)}
                      />
                    );
                  })
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Field>
          <div className="buttons">
            <button className="main_button" type="submit">
              Cargar
            </button>
          </div>
          <span className="NewMovement_error">{error}</span>
        </form>
        <Popup
          isActive={isOpenConfirmation}
          handleClose={() => setIsOpenconfirmation(false)}
        >
          <h1>Esta seguro de que quiere guardar los cambios</h1>
          <button
            className="NewMovement_safe"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {!isLoading ? (
              "Guardar"
            ) : (
              <img className="loading" src={logoNegro} alt="loading" />
            )}
          </button>
        </Popup>
      </>
    </>
  );
}
