import React from "react";
import "./Popup.scss";

export default function Popup({ isActive, children, handleClose }) {
  return (
    <>
      <div className={`Popup ${isActive ? "active" : ""}`}>
        <div className="Popup_outside" onClick={handleClose}></div>
        <div className="Popup_content">{children}</div>
      </div>
    </>
  );
}
