import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { familyAPI } from "../services";

export const fetchFamilyById = createAsyncThunk(
  "family/fetchByIdStatus",
  async (id) => {
    const response = await familyAPI.fetchById(id);
    return response.data;
  }
);

const initialState = {
  info: {},
};

export const familySlice = createSlice({
  name: "family",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchFamilyById.fulfilled, (state, action) => {
      // Add user to the state array
      state.info = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function

export default familySlice.reducer;
