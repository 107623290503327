import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userAPI, familyAPI } from "../services";

export const fetchUserById = createAsyncThunk(
  "users/fetchByIdStatus",
  async (userId) => {
    const response = await userAPI.fetchById(userId);
    return response.data;
  }
);

const initialState = {
  info: {},
  isLoading: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUser: (state) => {
      state.info = { ...state.info };
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchUserById.fulfilled, (state, action) => {
      // Add user to the state array
      state.info = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchUserById.pending, (state, action) => {
      // Add user to the state array
      state.isLoading = true;
    });
    builder.addCase(fetchUserById.rejected, (state, action) => {
      // Add user to the state array
      state.isLoading = false;
    });
  },
});

// Action creators are generated for each case reducer function

export const { clearUser } = userSlice.actions;

export default userSlice.reducer;
