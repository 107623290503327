import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "../../store/categories";
import useDidUpdateEffect from "../useDidUpdateEffect/useDidUpdateEffect";

export default function useCategories(type, selectedCategoryId) {
  const dispatch = useDispatch();
  const allCategories = useSelector((state) => state.categories.info);
  const { family } = useSelector((state) => state.overview);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);

  useDidUpdateEffect(() => {
    const fetchCat = async () => {
      setIsLoadingCategories(true);
      await dispatch(fetchCategories(family.id));
      setIsLoadingCategories(false);
    };
    if (family?.id && !allCategories.length && type) {
      fetchCat();
    }
  }, [type]);

  const orderByType = (x, y) => {
    return x.isDefault === y.isDefault ? 0 : x.isDefault ? 1 : -1;
  };

  const filterCategoriesByType = useCallback(
    (type) => {
      const filteredCategories = allCategories.filter(
        (category) => category.type === type
      );
      return filteredCategories.sort(orderByType);
    },
    [allCategories]
  );

  const getSubcategories = useCallback(
    (id) => {
      return filteredCategories.find((category) => category.id === id)
        ?.subCategories;
    },
    [filteredCategories]
  );

  useEffect(() => {
    if (allCategories.length) {
      setFilteredCategories(filterCategoriesByType(type));
    }
  }, [type, allCategories, filterCategoriesByType]);

  useEffect(() => {
    setSubCategories(getSubcategories(selectedCategoryId));
  }, [selectedCategoryId, getSubcategories]);

  return {
    filteredCategories,
    allCategories,
    subCategories,
    isLoadingCategories,
  };
}
