import { useAuthenticator } from "@aws-amplify/ui-react";
import { Amplify, Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export const awsExports = {
  REGION: "us-east-1",
  USER_POOL_ID: "us-east-1_v5G9gteyD",
  USER_POOL_APP_CLIENT_ID: "786ovkd4h69ufp2u932l3ec8os",
};

Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
  },
});

export function PrivateRoute() {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);
  return (
    <>
      {route !== "authenticated" ? (
        <Navigate to="/login" state={{ from: location }} replace />
      ) : (
        <Outlet />
      )}
    </>
  );
}

// export function PrivateRoute({ children }) {
//   const location = useLocation();
//   const { route } = useAuthenticator((context) => [context.route]);
//   if (route !== "authenticated") {
//     return <Navigate to="/login" state={{ from: location }} replace />;
//   }
//   return children;
// }
