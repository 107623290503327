import React, { useMemo, useState } from "react";
import "./NewAccount.scss";
import Field from "../../components/Field";
import { Dropdown } from "primereact/dropdown";
import Popup from "../../components/Popup/Popup";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { accountAPI } from "../../services";
import { accountType } from "../../constants";
import logoNegro from "../../assets/logonegro.png";

const typeList = [
  { value: accountType.BANK, label: "Banco" },
  { value: accountType.CASH, label: "Efectivo" },
  { value: accountType.CREDIT, label: "Credito" },
  { value: accountType.INVESTMENTS, label: "Inversiones" },
];

export default function NewAccount() {
  const blankForm = useMemo(() => {
    return {
      name: "",
      description: "",
      type: "",
      isCredit: false,
      userId: "",
    };
  }, []);

  let schema = yup.object().shape({
    name: yup.string().required().label("Nombre"),
    description: yup.string().required().label("Descripción"),
    type: yup.string().required().label("Tipo"),
    userId: yup.string().required().label("Dueño"),
  });

  const [data, setData] = useState(blankForm);
  const [error, setError] = useState("");
  const [isOpenConfirmation, setIsOpenconfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { users } = useSelector((state) => state.overview);

  const handleFieldChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const handleValidation = (e) => {
    e.preventDefault();
    schema
      .validate(data)
      .then((valid) => {
        setIsOpenconfirmation(true);
        setError("");
      })
      .catch((error) => {
        setError(error.errors);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (data.type === accountType.CREDIT) {
      data.isCredit = true;
    } else {
      data.isCredit = false;
    }
    await accountAPI.createNewAccount(data);
    setData(blankForm);
    setIsOpenconfirmation(false);
    setIsLoading(false);
  };

  // function timeout(ms) {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // }
  // async function sleep(fn, ...args) {
  //   await timeout(2000);
  //   return fn(...args);
  // }
  return (
    <>
      {" "}
      <>
        <form className="NewMovement" onSubmit={handleValidation}>
          <h2>Nueva Cuenta</h2>
          <Field
            required
            type="text"
            label="Nombre de la cuenta"
            name="name"
            value={data.name}
            onChange={(e) => {
              handleFieldChange("name", e.target.value);
            }}
          />
          <Field
            required
            type="text"
            label="Descripción"
            name="description"
            value={data.description}
            onChange={(e) => {
              handleFieldChange("description", e.target.value);
            }}
          />
          <Field label="Tipo de cuenta" name="type" required>
            <Dropdown
              value={data.type}
              onChange={(e) => handleFieldChange("type", e.value)}
              options={typeList}
              className="dropDown"
            />
          </Field>
          <Field label="Dueño de la cuenta" name="userId" required>
            <Dropdown
              value={data.userId}
              onChange={(e) => handleFieldChange("userId", e.value)}
              options={users}
              className="dropDown"
              optionValue="id"
              optionLabel="name"
            />
          </Field>
          <div className="buttons">
            <button className="main_button" type="submit">
              Cargar
            </button>
          </div>
          <span className="NewMovement_error">{error}</span>
        </form>
        <Popup
          isActive={isOpenConfirmation}
          handleClose={() => setIsOpenconfirmation(false)}
        >
          <h1>Esta seguro de que quiere guardar los cambios</h1>
          <button
            className="NewMovement_safe"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {!isLoading ? (
              "Guardar"
            ) : (
              <img className="loading" src={logoNegro} alt="loading" />
            )}
          </button>
        </Popup>
      </>
    </>
  );
}
