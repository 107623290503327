import React from "react";
import "./OverView.scss";
import { useSearchParams } from "react-router-dom";
import Pack from "./Pack";
import useOverviewInfo from "../../hooks/useOverviewInfo/useOverview";
import logoNegro from "../../assets/logonegro.png";
import { useSelector } from "react-redux";

export default function OverView() {
  const [overview, isLoading] = useOverviewInfo();
  const { isLoading: userIsLoading } = useSelector((state) => state.user);
  return (
    <div className="OverView">
      {isLoading || userIsLoading ? (
        <div className="OverView_loading">
          <img className="loading" src={logoNegro} alt="loading" />
        </div>
      ) : (
        overview.map((item) => {
          return (
            <Pack
              type={item.type}
              data={item.data}
              key={item.id}
              id={item.id}
            />
          );
        })
      )}
    </div>
  );
}
