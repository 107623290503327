import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./NewMovement.scss";
import Field from "../../components/Field";
import { Dropdown } from "primereact/dropdown";
import MoneyField from "../../components/MoneyField";
import useCategories from "../../hooks/useCategories/useCategories";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import Popup from "../../components/Popup/Popup";
import { movementAPI } from "../../services";
import { movementType } from "../../constants";
import logoNegro from "../../assets/logonegro.png";
import { useNavigate, useParams } from "react-router-dom";
import { appPaths } from "../../router";
import { clearUser } from "../../store/user";
import { Calendar } from "primereact/calendar";

export default function NewMovement() {
  const { users } = useSelector((state) => state.overview);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const [deleteValidation, setDeleteValidation] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (users.length) {
      setIsUsersLoading(false);
    } else {
      setIsUsersLoading(true);
    }
  }, [users]);

  const getMovement = useCallback(async (id) => {
    setPageIsLoading(true);
    const extraInfo = await movementAPI.getMovementById(id);
    setData({
      ...blankForm,
      ...extraInfo.data,
      date: new Date(extraInfo.data.date),
    });
    setPageIsLoading(false);
  }, []);

  useEffect(() => {
    if (id) {
      getMovement(id);
    }
  }, [id, getMovement]);

  const blankForm = useMemo(() => {
    return {
      description: "",
      type: "",
      amount: 0,
      categoryId: "",
      subCategory: "",
      date: "",
      accountId: "",
      billLink: "",
      note: "",
    };
  }, []);

  let schema = yup.object().shape({
    description: yup.string().required().label("Descripción"),
    type: yup.string().required().label("Tipo"),
    amount: yup.number().required().positive().integer().label("Cantidad"),
    categoryId: yup.string().required().label("Categoria"),
    subCategory: yup.string().nullable(),
    date: yup.date().required().label("Fecha"),
    accountId: yup.string().required().label("Cuenta"),
    billLink: yup.string().nullable(),
    note: yup.string().nullable(),
  });

  const [data, setData] = useState(blankForm);
  const [error, setError] = useState("");

  const { filteredCategories, subCategories, isLoadingCategories } =
    useCategories(data.type, data.categoryId);

  const handleFieldChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const typeList = [
    { label: "Compra", value: movementType.PURCHASE },
    { label: "Ingreso", value: movementType.INCOME },
  ];

  const [isOpenConfirmation, setIsOpenconfirmation] = useState(false);

  const handleValidation = (e) => {
    e.preventDefault();
    schema
      .validate(data)
      .then((valid) => {
        setIsOpenconfirmation(true);
        setError("");
      })
      .catch((error) => {
        setError(error.errors);
      });
  };

  const openDeleteValidation = () => {
    setDeleteValidation(true);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (id) {
      await movementAPI.editMovement(data);
      navigate(appPaths.HOME);
    } else {
      await movementAPI.createMovement(data);
    }
    dispatch(clearUser());
    setData(blankForm);
    setIsOpenconfirmation(false);
    setIsLoading(false);
  };

  const accountPlaceHolder = (id) => {
    let accountString = "";

    users.forEach((user) => {
      const selectedAccount = user.accounts.find(
        (account) => account.id === id
      );
      if (selectedAccount) {
        accountString = user.name + " - " + selectedAccount.name;
        return;
      }
    });

    return accountString;
  };

  const handleDelete = async () => {
    setIsLoading(true);
    await movementAPI.editMovement({ id: id, accountId: null });
    dispatch(clearUser());
    navigate(appPaths.HOME);
    setIsLoading(false);
  };

  return (
    <>
      {pageIsLoading && (
        <div className="NewMovement_loading">
          <img className="loading" src={logoNegro} alt="loading" />
        </div>
      )}
      <form className="NewMovement" onSubmit={handleValidation}>
        <h2>Nuevo movimiento</h2>
        <div className="NewMovement_container">
          <Field
            required
            type="text"
            label="Titulo del Movimiento"
            name="description"
            value={data.description}
            onChange={(e) => {
              handleFieldChange("description", e.target.value);
            }}
          />
          <Field label="Tipo de movimiento" name="type" required>
            <Dropdown
              value={data.type}
              onChange={(e) => handleFieldChange("type", e.value)}
              options={typeList}
              placeholder="Tipo de gasto"
              className="dropDown"
            />
          </Field>
          <MoneyField
            required
            label="Cantidad"
            name="amount"
            value={data.amount}
            onChange={(e) => {
              handleFieldChange("amount", e.target.value);
            }}
          />

          <Field
            label="Categoria"
            name="categoryId"
            required
            isLoading={isLoadingCategories}
          >
            <Dropdown
              value={data.categoryId}
              onChange={(e) => handleFieldChange("categoryId", e.value)}
              options={filteredCategories}
              placeholder="Categoria de gastos"
              className="dropDown"
              optionValue="id"
              optionLabel="name"
            />
          </Field>
          <Field
            label="Subcategoria"
            name="subCategory"
            isLoading={isLoadingCategories}
          >
            <Dropdown
              value={data.subCategory}
              onChange={(e) => handleFieldChange("subCategory", e.value)}
              options={subCategories}
              placeholder="Subcategoria de gastos"
              className="dropDown"
            />
          </Field>
          <Field required type="datetime-local" label="Fecha">
            <Calendar
              value={data.date}
              onChange={(e) => handleFieldChange("date", e.value)}
              showTime
              hourFormat="24"
              locale="en"
            />
          </Field>
          <Field
            label="Cuenta"
            name="account"
            required
            isLoading={isUsersLoading}
          >
            <Dropdown
              value={data.accountId}
              onChange={(e) => handleFieldChange("accountId", e.value.id)}
              options={users}
              optionLabel="name"
              optionGroupLabel="name"
              optionGroupChildren="accounts"
              className="dropDown"
              placeholder={accountPlaceHolder(data.accountId)}
            />
          </Field>
          <Field
            type="text"
            label="Link de factura"
            name="billLink"
            value={data.billLink}
            onChange={(e) => {
              handleFieldChange("billLink", e.target.value);
            }}
          />
          <Field label="Nota" name="note">
            <textarea
              rows="5"
              value={data.note}
              onChange={(e) => {
                handleFieldChange("note", e.target.value);
              }}
            ></textarea>
          </Field>
        </div>

        <div className="buttons">
          {" "}
          <button className="main_button" type="submit">
            {id ? "Editar" : "Cargar"}
          </button>
          {id && (
            <button
              className="main_button danger"
              onClick={openDeleteValidation}
            >
              Eliminar
            </button>
          )}
        </div>
        <span className="NewMovement_error">{error}</span>
      </form>
      <Popup
        isActive={isOpenConfirmation}
        handleClose={() => setIsOpenconfirmation(false)}
      >
        <h1>Esta seguro de que quiere guardar los cambios</h1>
        <button
          className="NewMovement_safe"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {!isLoading ? (
            "Guardar"
          ) : (
            <img className="loading" src={logoNegro} alt="loading" />
          )}
        </button>
      </Popup>
      <Popup
        isActive={deleteValidation}
        handleClose={() => setDeleteValidation(false)}
      >
        <h1>¿Estas seguro de que quieres eliminar este movimiento?</h1>
        <button
          className="NewMovement_safe"
          onClick={handleDelete}
          disabled={isLoading}
        >
          {!isLoading ? (
            "Eliminar"
          ) : (
            <img className="loading" src={logoNegro} alt="loading" />
          )}
        </button>
      </Popup>
    </>
  );
}
