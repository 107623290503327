import React, { useState } from "react";
import "./SideMenu.scss";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { appPaths } from "../../../../../../router";
import menuIcon from "../../../../../../assets/menu.svg";

export default function SideMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const { signOut } = useAuthenticator((context) => [context.route]);
  const navigate = useNavigate();

  return (
    <div className={`SideMenu ${isOpen ? "open" : ""}`}>
      <div className="SideMenu_display">
        <button
          className="SideMenu_display_trigger"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {isOpen ? (
            <img src={menuIcon} alt="Close Menu Button"></img>
          ) : (
            <img src={menuIcon} alt="Open Menu Button" />
          )}
        </button>
      </div>
      {isOpen && (
        <>
          <div className="outSideBlock" onClick={() => setIsOpen(false)}></div>
          <div className="SideMenu_container">
            <ul className="SideMenu_container_items">
              <li className="SideMenu_container_items_item">
                <button
                  onClick={() => {
                    navigate(appPaths.HOME);
                    setIsOpen(false);
                  }}
                >
                  Home
                </button>
              </li>
              <li className="SideMenu_container_items_item">
                <button
                  onClick={() => {
                    navigate(appPaths.NEWMOVEMENT);
                    setIsOpen(false);
                  }}
                >
                  Nuevo Movimiento
                </button>
              </li>
              <li className="SideMenu_container_items_item">
                <button
                  onClick={() => {
                    navigate(appPaths.NEW_ACCOUNT);
                    setIsOpen(false);
                  }}
                >
                  Nueva Cuenta{" "}
                </button>
              </li>
              <li className="SideMenu_container_items_item">
                <button
                  onClick={() => {
                    navigate(appPaths.NEW_CATEGORY);
                    setIsOpen(false);
                  }}
                >
                  Nueva Categoria
                </button>
              </li>
              <li className="SideMenu_container_items_item">
                <button onClick={signOut}>Log out</button>
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
}
