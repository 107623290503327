import React, { useState } from "react";
import Field from "../../components/Field";
import { Dropdown } from "primereact/dropdown";
import "./CreateFamily.scss";
import { familyAPI, userAPI } from "../../services";
import { useNavigate } from "react-router-dom";
import { appPaths } from "../../router";
import { useSelector } from "react-redux";
import logoNegro from "../../assets/logonegro.png";

const newFamilyTypes = [
  {
    value: "NEW",
    label: "Nueva Familia",
  },
  {
    value: "EXISTING",
    label: "Familia Existente",
  },
];

export default function CreateFamily() {
  const [newFamilyType, setNewFamilyType] = useState("");
  const [newFamily, setNewFamily] = useState({ name: "" });
  const [joinFamily, setJoinFamily] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { info: userInfo } = useSelector((state) => state.user);

  const navigate = useNavigate();

  const handleCreateNewFamily = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await familyAPI.createFamily(newFamily);
    navigate(appPaths.HOME);
    setIsLoading(false);
  };

  const handleRequestJoinFamily = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await userAPI.addUserRequest(joinFamily, {
      name: userInfo.name,
      email: userInfo.email,
      userId: userInfo.id,
    });
    navigate(appPaths.HOME);
    setIsLoading(false);
  };

  return (
    <div className="CreateFamliy">
      <h1>Nueva Familia </h1>
      <Field label="¿A que tipo de familia vas a unirte?" name="Family">
        <Dropdown
          value={newFamilyType}
          onChange={(e) => setNewFamilyType(e.target.value)}
          options={newFamilyTypes}
          className="dropDown"
        />
      </Field>
      {newFamilyType === "NEW" && (
        <form className="CreateFamliy_form" onSubmit={handleCreateNewFamily}>
          <Field
            type="text"
            label="Nombre de la nueva Familia"
            required
            value={newFamily.name}
            onChange={(e) => {
              setNewFamily({ ...newFamily, name: e.target.value });
            }}
          />
          <button className="main_button" type="submit" disabled={isLoading}>
            {!isLoading ? (
              "Crear Nueva Familia"
            ) : (
              <img className="loading" src={logoNegro} alt="loading" />
            )}
          </button>
        </form>
      )}
      {newFamilyType === "EXISTING" && (
        <form className="CreateFamliy_form" onSubmit={handleRequestJoinFamily}>
          <Field
            type="text"
            label="Ingrese el Id de la familia a la que quiere unirse"
            required
            value={joinFamily}
            onChange={(e) => {
              setJoinFamily(e.target.value);
            }}
          />
          <button className="main_button" type="submit" disabled={isLoading}>
            {!isLoading ? (
              "Enviar solicitud"
            ) : (
              <img className="loading" src={logoNegro} alt="loading" />
            )}
          </button>
        </form>
      )}
    </div>
  );
}
